<template>
  <perfect-scrollbar class="p-4 ContentsBox">

    <b-table striped hover
      id='fieldTable'
      table-class="cmsAppTable"
      thead-class="tableHead"
      tbody-tr-class="dataWrap"
      :items="recordList"
      :fields="header"
      show-empty
    >
      <template v-slot:cell(app)="row">
        <b-link
          v-if="!row.item.is_past"
          @click="exportPdf(1, row.item.apptypeId, row.item.appId)"
          class="ml-1">
          PDF
        </b-link>
        <b-link
          v-if="row.item.is_past"
          @click="exportPastPdf(row.index, row.item.is_tie, row.item.typeName)"
          class="ml-1">
          PDF
        </b-link>
      </template>
      <template v-slot:cell(report)="row">
        <b-link
          v-if="!row.item.is_past && row.item.repId"
          @click="exportPdf(2, row.item.apptypeId, row.item.repId)"
          class="ml-1">
          PDF
        </b-link>
      </template>
    </b-table>

  </perfect-scrollbar>
</template>

<script>


export default {
  name: 'cmsApplicationRecord',
  components: {
  },
  props: {
    recordList: { type: Array, default: () => [] },
    exportPdf: { type: Function },
    exportPastPdf: { type: Function },
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    header() {
      return [
        { key: 'year', label: '年度', sortable: true },
        { key: 'userName', label: '申請者名', sortable: true },
        { key: 'typeName', label: '申請区分', sortable: true },
        { key: 'theme', label: '申請テーマ', sortable: true },
        { key: 'status', label: '結果', sortable: true },
        { key: 'app', label: '申請', sortable: false },
        { key: 'report', label: '報告', sortable: false },
      ];
    },
  },
  // ロード画面
  created() {
  },
};
</script>

<style scoped>
  .opacity03 {
    opacity: 0.3;
  }

  .h-50px {
    height: 50px;
  }

  .label {
    height: 50px;
  }

  .labelText {
    position: absolute;
    top: 10px;
  }

  .error {
    color: #dc3545;
  }
</style>
