<template>
  <div>
    <div class="contentsWidth mx-auto mt-4 mb-3">
      <div class="">
        <p class="title mb-0">{{base.year}}年度 {{base.name}} - 選考評価</p>
        <hr class="title">
      </div>
    </div>
    <div class="contentsWidth mx-auto err-wrap">
      <p
        class="mb-0"
        v-if="isChangeUser">
          「{{selectedMarkerName}}」ユーザーで選考評価を入力中です。
      </p>
      <p class="mb-0">{{this.errMessages['app']}}</p>
      <p class="mb-0"
        v-if="!onGoing || this.errMessages['onGoing']">選考評価期間外のため、登録できません。</p>
      <p class="mb-0">{{this.errMessages['item']}}</p>
    </div>

    <perfect-scrollbar
      class="contentsWidth mx-auto evaluation-wrap my-3 p-3"
      :options='scrollOption'>
      <div class="flex flex-between">
        <span class="bold">
          評価入力
        </span>
        <span>
          <span class="bold mr-5">
            申請者氏名： {{userInfo.sei}} {{userInfo.mei}}
            </span>
          <span class="bold">申請番号： {{base.code}}</span>
        </span>
      </div>
      <hr class="mt-1 evaluation-line">

      <div class="err-wrap my-2">
        <p
          v-for="(msg, index) in this.errMessages['form']" :key="`${index}_form_err`"
          class="mb-0">{{msg}}</p>
      </div>

      <div class="evaluation-item-wrap flex flex-between">
        <div
          v-for="(item, index) in evalItems[1]" :key="`${index}_1`"
          class="evaluation-item">
          <div>{{item.label}}</div>
          <div>
            <b-form-select
              v-model="evalFormData[item.item_id].value"
              :options="itemOption(item.min_param, item.max_param)"
              :class="[
                errMessages['form'] && errMessages['form'][`item_${item.item_id}`] ? 'errItem' : '',
              ]"
              :disabled="!onGoing"
              >
                <template v-slot:first>
                  <option :value="null">-- 未選択 --</option>
                </template>
            </b-form-select>
          </div>
        </div>
        <div
          v-for="(item, index) in evalItems[2]" :key="`${index}_2`"
          class="evaluation-item">
          <div>{{item.label}}</div>
          <div>
            <b-form-select
              v-model="evalFormData[item.item_id].value"
              :options="itemOption(item.min_param, item.max_param)"
              :class="[
                errMessages['form'] && errMessages['form'][`item_${item.item_id}`] ? 'errItem' : '',
              ]"
              :disabled="!onGoing"
              >
                <template v-slot:first>
                  <option :value="null">-- 未選択 --</option>
                </template>
            </b-form-select>
          </div>
        </div>
      </div>

      <div
        v-for="(item, index) in evalItems[3]" :key="`${index}_3`"
        class="mt-2 w100">
        <span>{{item.label}}</span>
        <b-form-textarea
          v-model="evalFormData[item.item_id].value"
          rows="3"
          max-rows="6"
          maxlength="800"
          placeholder="入力してください。"
          :class="[
            errMessages['form'] && errMessages['form'][`item_${item.item_id}`] ? 'errItem' : '',
          ]"
          :disabled="!onGoing"
          />
      </div>

      <div
        v-for="(evalData, stage) in preEvalData" :key="`preEval_${stage}`"
        class="mt-4">
        <div>
          <div class="inlineFlex label-wrap">{{stage}}次審査の評価</div>
          <div class="inlineFlex evaluation-table-wrap">
            <b-table
              show-empty
              id='preEvalViewTable'
              table-class="evalViewTable"
              thead-class=""
              tbody-tr-class="evalDataWrap"
              :fields="preEvalHeaders[stage]"
              :items="preEvalItems[stage]"
            >
              <template v-slot:empty="scope">
                <p class="none-data">選考評価担当者の割当がありません。</p>
              </template>
            </b-table>
          </div>
        </div>
        <div class="mt-2">
          <div class="inlineFlex label-wrap"></div>
          <div class="inlineFlex evaluation-table-wrap">
            <b-table
              id='preEvalCommentTable'
              table-class="evalCommentTable"
              thead-class=""
              tbody-tr-class="evalDataWrap"
              :fields="preEvalHeaders4comment[stage]"
              :items="preEvalItems[stage]"
            >
            </b-table>
          </div>
        </div>
      </div>

    </perfect-scrollbar>

    <b-tabs
      class="gas-tab contentsWidth mx-auto "
      nav-wrapper-class="gas-tab-wrap">
      <b-tab>
        <template v-slot:title>
          <span>申請者情報</span>
        </template>
        <v-app-userinfo
          :writable="false"
          :close_datetime="base.close_datetime"/>
      </b-tab>
      <b-tab
        v-for="(page, index) in pages" :key="index">
        <template v-slot:title>
          <span>{{page.name}}</span>
        </template>
        <v-app-form
          :writable="false"
          :pageIndex="index"
          :assets="assets"
          :downloadFormFile="page.name === '追加資料' ? downloadFormAttFile: downloadFormFile"/>
      </b-tab>
      <b-tab
        v-if="recordList.length > 0">
        <template v-slot:title>
          <span>実績</span>
        </template>
        <v-app-record
          :recordList.sync="recordList"
          :exportPdf="exportPdf"
          :exportPastPdf="exportPastPdf"/>
      </b-tab>
    </b-tabs>

    <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
      <b-button
        v-show="hasPrevApp"
        @click="prevApp"
        class="btn btn-primary btn-lg mr-4 bold">前の申請</b-button>
      <b-button
        class="btn btn-lg mr-4 bold"
        to="/cms/evaluation/list">一覧に戻る</b-button>
      <b-button
        @click="save"
        variant="info"
        :disabled="!onGoing"
        class="btn btn-primary btn-lg mr-4 bold">更新</b-button>
      <b-button
        @click="exportPdf(1, apptypeId, appId)"
        variant="info"
        class="btn btn-primary btn-lg mr-4 bold">PDF出力</b-button>
      <b-button
        v-show="hasNextApp"
        @click="nextApp"
        class="btn btn-primary btn-lg bold">次の申請</b-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import download from '@/modules/download';
import Userinfo from '@/components/parts/application/Userinfo.vue';
import AppForm from '@/components/gas/parts/Form.vue';
import Record from '@/components/cms/applications/Record.vue';

export default {
  name: 'cmsEvaluationEdit',
  components: {
    'v-app-userinfo': Userinfo,
    'v-app-form': AppForm,
    'v-app-record': Record,
  },
  data() {
    return {
      stageId: null,
      // 選考評価の入力フォームデータ
      evalFormData: {},
      errMessages: {},
      stageData: {},
      base: {},
      assets: {},
      scrollOption: {
        suppressScrollX: true,
      },
      evalItems: {},
      preEvalData: {},
      pastList: [],
      recordAppList: [],
      seriesData: {},
      // 強制紐付け
      tiePastList: [],
      tieSysList: [],
      attachment_id: null,
    };
  },
  computed: {
    apptypeId() {
      return this.$store.state.gasApplicationForm.application_type_id;
    },
    appId() {
      return this.$store.state.gasApplicationForm.application_id;
    },
    pages() {
      return this.$store.state.gasApplicationForm.pages;
    },
    userInfo() {
      return this.$store.state.userInfo.inputData;
    },
    filterdIdsList() {
      return this.$store.state.cmsEvaluationSearch.filterdIdsList;
    },
    filterdIdsListIndex() {
      return this.filterdIdsList.findIndex((data) => {
        const findAppId = data.appId === Number(this.appId);
        const findStageId = data.stageId === Number(this.stageId);
        return findAppId && findStageId;
      });
    },
    hasNextApp() {
      if (this.filterdIdsList.length === 0) {
        return false;
      }
      const newIndex = this.filterdIdsListIndex + 1;
      return newIndex < this.filterdIdsList.length;
    },
    hasPrevApp() {
      if (this.filterdIdsList.length === 0) {
        return false;
      }
      const newIndex = this.filterdIdsListIndex - 1;
      return newIndex >= 0;
    },
    itemOption() {
      return (min, max) => {
        const itemOption = [];
        for (let i = min; i <= max; i += 1) {
          itemOption.push({ value: i, text: i });
        }
        return itemOption;
      };
    },
    onGoing() {
      const now = moment();
      const from = moment(this.stageData.from);
      const to = moment(this.stageData.to);
      return now.isBetween(from, to);
    },
    preEvalHeaders() {
      const headers = {};
      Object.keys(this.preEvalData).forEach((stage) => {
        const stageData = this.preEvalData[stage];
        headers[stage] = [];
        const thName = { key: 'name', label: '', sortable: false };
        headers[stage].push(thName);
        stageData.fields['1'].forEach((item) => {
          const th = {
            // bootstrap-vueのテーブルのkeyはstringのみ
            key: String(item.item_id),
            label: item.label,
            sortable: false,
          };
          headers[stage].push(th);
        });
        stageData.fields['2'].forEach((item) => {
          const th = {
            // bootstrap-vueのテーブルのkeyはstringのみ
            key: String(item.item_id),
            label: item.label,
            sortable: false,
          };
          headers[stage].push(th);
        });
      });
      return headers;
    },
    preEvalHeaders4comment() {
      const headers = {};
      Object.keys(this.preEvalData).forEach((stage) => {
        const stageData = this.preEvalData[stage];
        headers[stage] = [];
        const thName = { key: 'name', label: '', sortable: false };
        headers[stage].push(thName);
        stageData.fields['3'].forEach((item) => {
          const th = {
            // bootstrap-vueのテーブルのkeyはstringのみ
            key: String(item.item_id),
            label: item.label,
            sortable: false,
          };
          headers[stage].push(th);
        });
      });
      return headers;
    },
    preEvalItems() {
      const items = {};
      const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
      Object.keys(this.preEvalData).forEach((stage) => {
        const stageData = this.preEvalData[stage];
        items[stage] = [];
        Object.keys(stageData.assign).forEach((key, index) => {
          const data = {};
          data.name = `担当者${alphabets[index]}`;
          if (!stageData.evals[key]) {
            items[stage].push(data);
            return;
          }
          stageData.evals[key].items.forEach((item) => {
            const idKey = String(item.evaluation_item_id);
            // evaluationのデータがない時はコメントを参照する
            if (!item.evaluation && item.evaluation !== 0) {
              data[idKey] = item.comment;
            } else {
              data[idKey] = item.evaluation;
            }
          });
          items[stage].push(data);
        });
      });
      return items;
    },
    recordList() {
      const recordList = [];
      this.recordAppList.forEach((app) => {
        const data = {
          appId: app.app_id,
          repId: app.report_id,
          userName: app.user_name,
          year: app.year,
          apptypeId: app.apptype_id,
          typeName: app.apptype_name,
          theme: app.theme,
          status: app.status === 10 ? '採択' : '', // 採択
          is_past: false,
          is_tie: false,
        };
        recordList.push(data);
      });

      this.pastList.forEach((past) => {
        const data = {
          userName: `${past.sei} ${past.mei}`,
          year: past.year,
          series_id: past.series_id,
          typeName: this.seriesData[past.series_id].name,
          theme: past.theme,
          status: past.status === 1 ? '採択' : '',
          past_app_id: past.id,
          is_past: true,
          is_tie: false,
        };
        recordList.push(data);
      });

      this.tieSysList.forEach((app) => {
        const data = {
          appId: app.appId,
          repId: app.repId,
          userName: app.user_name,
          year: app.year,
          apptypeId: app.apptypeId,
          typeName: app.typeName,
          theme: app.theme,
          status: app.status === 10 ? '採択' : '',
          past_app_id: app.id,
          is_past: false,
          is_tie: true,
        };
        recordList.push(data);
      });

      this.tiePastList.forEach((past) => {
        const data = {
          userName: past.user_name,
          year: past.year,
          typeName: past.typeName,
          theme: past.theme,
          status: past.status === 1 ? '採択' : '', // 採択
          past_app_id: past.id,
          is_past: true,
          is_tie: true,
        };
        recordList.push(data);
      });
      return recordList;
    },
    filter() {
      return this.$store.state.cmsEvaluationSearch.filter;
    },
    isChangeUser() {
      const loginUserId = this.$store.state.auth.user_id;
      return this.filter.marker !== null && this.filter.marker !== loginUserId;
    },
    selectedMarkerName() {
      return this.$store.state.cmsEvaluationSearch.selectedMarkerName;
    },
  },
  methods: {
    async fetchData() {
      const param = {
        appId: this.$route.params.appId,
        stageId: this.$route.params.stageId,
        marker: this.filter.marker,
      };
      this.stageId = this.$route.params.stageId;
      const response = await api.send('/api/cms/evaluation/edit', param)
        .catch((err) => {
          console.error(err);
        });
      if (!response) {
        await this.alert('申請のデータを取得できませんでした。');
        this.$router.push({ path: 'cms/evaluation/list' });
        return;
      }
      const { base } = response.data;
      this.base = base;
      // this.base.applicationTypeId = base.application_type_id;
      this.base.close_datetime = base.close_datetime;
      this.assets = response.data.assets;
      const { pages, answers, attachmentId } = response.data;
      this.evalItems = response.data.evalItems;
      this.makeEvalFormData();
      this.$store.commit('userInfo/setDbData', response.data.userInfo);
      this.$store.commit('gasApplicationForm/setUserId', base.user_id);
      this.$store.commit('gasApplicationForm/setDbData', pages);
      this.$store.commit('gasApplicationForm/setApplicationTypeId', base.application_type_id);
      this.$store.commit('gasApplicationForm/setApplicationId', Number(param.appId));
      this.$store.commit('gasApplicationInput/setDbData', { pages, answers });
      this.stageData = response.data.stage;
      // 選考評価の入力値のセット
      this.setEvalFormData(response.data.evalFormData);
      // 前の評価段階のデータ
      this.preEvalData = response.data.preEval;
      // レコード
      this.pastList = response.data.pastList;
      this.seriesData = response.data.seriesData;
      this.recordAppList = response.data.recordAppList;
      this.tiePastList = response.data.tiePastList;
      this.tieSysList = response.data.tieSysList;
      this.attachment_id = attachmentId;
    },
    setEvalFormData(evalFormData) {
      evalFormData.forEach((data) => {
        const value = data.type !== 3 ? data.evaluation : data.comment;
        this.$set(this.evalFormData[String(data.item_id)], 'value', value);
      });
    },
    makeEvalFormData() {
      const evalFormData = {};
      Object.keys(this.evalItems).forEach((type) => {
        this.evalItems[type].forEach((item) => {
          evalFormData[item.item_id] = {
            type,
            value: null,
          };
        });
      });
      this.evalFormData = evalFormData;
    },
    async save() {
      this.errMessages = {};
      this.$store.dispatch('page/onWaiting');
      const param = {
        appId: this.$route.params.appId,
        stageId: this.$route.params.stageId,
        evalFormData: this.evalFormData,
        marker: this.filter.marker,
      };
      const response = await api.send('/api/cms/evaluation/save', param)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert('入力内容に誤りがありました。修正してください。', false);
            this.errMessages = err.response.data.messages;
          } else {
            await this.alert('登録に失敗しました。', false);
          }
          return false;
        });
      if (!response) {
        this.$store.dispatch('page/offWaiting');
        return;
      }
      await this.alert('登録しました。', false);
      this.fetchData();
      this.$store.dispatch('page/offWaiting');
    },
    // 実績のPDF出力にも使えるように引数で渡す
    // type 1=>申請，2=>報告
    async exportPdf(kind, apptypeId, appId) {
      this.$store.dispatch('page/onWaiting');
      const params = {
        kind,
        apptypeId,
        appId,
        stageId: this.$route.params.stageId,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/evaluation/export/pdf', params, requireBlob)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      const fileName = `${this.base.year}_${this.base.name}_${this.userInfo.sei}${this.userInfo.mei}.pdf`;
      download.blob(response.data, fileName);
    },
    async exportPastPdf(index, isTie, typeName) {
      this.$store.dispatch('page/onWaiting');
      const recordData = this.recordList[index];
      const param = {
        pastAppId: recordData.past_app_id,
        appId: this.appId,
        stageId: this.$route.params.stageId,
        userId: this.base.user_id,
        isTie,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/evaluation/export/pdfPast', param, requireBlob)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      const seriesName = typeName;
      const userName = `${this.userInfo.sei}${this.userInfo.mei}`;
      const fileName = `${recordData.year}_${seriesName}_${userName}.pdf`;
      download.blob(response.data, fileName);
    },
    async downloadFormFile(fieldId, optionId, fileName) {
      const param = {
        application_id: this.$route.params.appId,
        field_id: fieldId,
        option_id: optionId,
        answer_no: 1, // ファイルは当面１つ固定
        stageId: this.$route.params.stageId,
      };
      param.user_id = this.$store.state.gasApplicationForm.user_id;
      const url = '/api/cms/evaluation/download/uploadedFile';

      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
    async downloadFormAttFile(fieldId, optionId, fileName) {
      const param = {
        application_id: this.$route.params.appId,
        attachment_id: this.attachment_id,
        field_id: fieldId,
        option_id: optionId,
        answer_no: 1, // ファイルは当面１つ固定
      };
      param.user_id = this.$store.state.gasApplicationForm.user_id;
      const url = '/api/cms/attachments/download/uploadedFile';

      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
    async nextApp() {
      this.errMessages = {};
      const newIndex = this.filterdIdsListIndex + 1;
      const data = this.filterdIdsList[newIndex];
      this.$router.push({ path: `/cms/evaluation/edit/${data.appId}/${data.stageId}` });
      this.$store.dispatch('page/onLoading');
      await this.fetchData();
      this.$store.dispatch('page/offLoading');
    },
    async prevApp() {
      this.errMessages = {};
      const newIndex = this.filterdIdsListIndex - 1;
      const data = this.filterdIdsList[newIndex];
      this.$router.push({ path: `/cms/evaluation/edit/${data.appId}/${data.stageId}` });
      this.$store.dispatch('page/onLoading');
      await this.fetchData();
      this.$store.dispatch('page/offLoading');
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    await this.fetchData();
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style>

  .table thead th {
    border-top-width: 0;
    min-height: 120px;
  }

  .table thead th, .table td {
    border-color: #BBB !important;
  }

  /* 前段階評価 */
  #preEvalViewTable thead th:nth-of-type(1), #preEvalCommentTable thead th:nth-of-type(1) {
    width: 140px !important;
    padding: 5px;
    text-align:center;
  }
  #preEvalViewTable tbody td:not(:first-of-type) {
    text-align:center;
  }
  #preEvalViewTable thead th:not(:first-of-type) {
    width: 180px !important;
    padding: 5px;
    text-align:center;
  }
  #preEvalViewTable thead th:last-of-type {
    width: 130px !important;
    padding: 5px;
  }
  #preEvalCommentTable>tbody>tr>td {
    white-space:pre-wrap;
    word-wrap:break-word;
  }

</style>

<style scoped>
  select, input {
    height: 50px;
  }
  .stage-wrap {
    width: 200px;
    display: inline-block;
    margin-right: 20px;
  }
  .grader-wrap {
    width: 400px;
    display: inline-block;
  }

  .left-box {
    width: 620px;
    height: 100%;
    border-width: 1px;
    border-color: #CCCCCC;
  }

  .middle-box {
    width: 60px;
    height: 100%;
  }
  .arrow-wrap {
    position: relative;
  }
  .arrow-left {
    position: absolute;
    top: 370px;
    left: 9px;
    cursor: pointer;
  }
  .arrow-right {
    position: absolute;
    top: 305px;
    left: 9px;
    cursor: pointer;
  }

  .arrow-left.disabled, .arrow-right.disabled {
    cursor: not-allowed;
  }


  .right-box {
    width: 400px;
    height: 100%;
  }

  .main-box {
    background-color: #fff;
    border:solid 1px #CCC;
  }

  .main-box.left {
    height: 618px;
  }

  .main-box.right {
    height: 463px;
  }

  .item {
    width: 100%;
    height: 40px;
    background-color: #E6E6E6;
    border-radius: 25px;
    padding: 9px 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 7px 0;
    cursor: pointer;
  }

  .item.checked {
    background-color: rgba(18, 40, 137, 0.2);
  }

  #selected-wrap {
    min-height: 100%;
  }
  #selectable-wrap {
    min-height: 100%;
  }

  #selected-scroll-wrap.disable {
    background-color: #F3F3F3;
    cursor: not-allowed;
  }

  .assign_number-wrap>input {
    display: inline-block;
  }

  .assign_number {
    width: 188px;
  }

  .notice-wrap {
    color: #D56E6E;
  }

  .w100 {
    width: 100%;
  }


  /** 選考評価関連 */
  .evaluation-wrap {
    height: 300px;
    max-height: 300px;
    background-color: #DDD;
  }

  .evaluation-line {
    height: 2px;
    background-color: #BBB;
  }

  .evaluation-item {
    width: 155px;
  }

  .label-wrap {
    width: 14%;
  }
  .evaluation-table-wrap {
    width: 86%;
  }

  .err-wrap>p{
    color: #dc3545;
  }

  .errItem {
    border:solid 1px #dc3545 !important;
  }
</style>
